var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.profileData).length)?_c('div',{attrs:{"id":"user-profile"}},[_c('profile-header',{attrs:{"header-data":_vm.profileData.header}}),_c('section',{attrs:{"id":"profile-info"}},[_c('b-card',[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Primeiro Nome","label-for":"v-firstName"}},[_c('b-form-input',{attrs:{"id":"v-firstName","state":errors.length > 0 ? false : null,"placeholder":"descrição","disabled":!_vm.verificarPermissao('ROLE_USER_ATUALIZAR') ||
                      !_vm.verificarPermissao('ROLE_USER_INSERIR')},model:{value:(_vm.userPlace.firstName),callback:function ($$v) {_vm.$set(_vm.userPlace, "firstName", $$v)},expression:"userPlace.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1238766778)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ultimo Nome","label-for":"v-lastName"}},[_c('b-form-input',{attrs:{"id":"v-lastName","state":errors.length > 0 ? false : null,"placeholder":"descrição","disabled":!_vm.verificarPermissao('ROLE_USER_ATUALIZAR') ||
                      (!_vm.verificarPermissao('ROLE_USER_INSERIR') &&
                        !_vm.profileData.id)},model:{value:(_vm.userPlace.lastName),callback:function ($$v) {_vm.$set(_vm.userPlace, "lastName", $$v)},expression:"userPlace.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4198320521)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"v-email"}},[_c('b-form-input',{attrs:{"id":"v-email","type":"email","placeholder":"email","state":errors.length > 0 ? false : null,"disabled":!_vm.verificarPermissao('ROLE_USER_ATUALIZAR') ||
                      !_vm.verificarPermissao('ROLE_USER_INSERIR')},model:{value:(_vm.userPlace.email),callback:function ($$v) {_vm.$set(_vm.userPlace, "email", $$v)},expression:"userPlace.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,898140630)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Perfil","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Perfil","label-for":"v-role"}},[_c('v-select',{attrs:{"reduce":function (comboPerfils) { return comboPerfils.id; },"label":"nome","options":_vm.comboPerfils,"state":errors.length > 0 ? false : null,"disabled":true},model:{value:(_vm.userPlace.perfil),callback:function ($$v) {_vm.$set(_vm.userPlace, "perfil", $$v)},expression:"userPlace.perfil"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2078452274)})],1),(_vm.profileData.id)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Atico","label-for":"v-ativo"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.profileData.ativo,"name":"check-button","switch":"","inline":"","disabled":!_vm.verificarPermissao('ROLE_USER_ATUALIZAR') ||
                    !_vm.verificarPermissao('ROLE_USER_INSERIR')},model:{value:(_vm.profileData.ativo),callback:function ($$v) {_vm.$set(_vm.profileData, "ativo", $$v)},expression:"profileData.ativo"}},[_vm._v(" "+_vm._s(_vm.profileData.ativo ? 'Sim' : 'Não')+" ")])],1)],1):_vm._e(),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_USER_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_USER_ATUALIZAR'),"disable-form":false,"loadingButton":_vm.loading,"showCancel":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }