<template>
  <div v-if="Object.keys(profileData).length" id="user-profile">
    <profile-header :header-data="profileData.header" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-card>
        <validation-observer ref="form">
          <b-form @submit.prevent>
            <b-row>
              <!-- first name -->
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="firstName"
                  rules="required"
                >
                  <b-form-group label="Primeiro Nome" label-for="v-firstName">
                    <b-form-input
                      id="v-firstName"
                      v-model="userPlace.firstName"
                      :state="errors.length > 0 ? false : null"
                      placeholder="descrição"
                      :disabled="
                        !verificarPermissao('ROLE_USER_ATUALIZAR') ||
                        !verificarPermissao('ROLE_USER_INSERIR')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="lastName"
                  rules="required"
                >
                  <b-form-group label="Ultimo Nome" label-for="v-lastName">
                    <b-form-input
                      id="v-lastName"
                      v-model="userPlace.lastName"
                      :state="errors.length > 0 ? false : null"
                      placeholder="descrição"
                      :disabled="
                        !verificarPermissao('ROLE_USER_ATUALIZAR') ||
                        (!verificarPermissao('ROLE_USER_INSERIR') &&
                          !profileData.id)
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group label="Email" label-for="v-email">
                    <b-form-input
                      id="v-email"
                      v-model="userPlace.email"
                      type="email"
                      placeholder="email"
                      :state="errors.length > 0 ? false : null"
                      :disabled="
                        !verificarPermissao('ROLE_USER_ATUALIZAR') ||
                        !verificarPermissao('ROLE_USER_INSERIR')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- email -->
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="Perfil"
                  rules="required"
                >
                  <b-form-group label="Perfil" label-for="v-role">
                    <v-select
                      v-model="userPlace.perfil"
                      :reduce="(comboPerfils) => comboPerfils.id"
                      label="nome"
                      :options="comboPerfils"
                      :state="errors.length > 0 ? false : null"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col v-if="profileData.id" cols="12">
                <b-form-group label="Atico" label-for="v-ativo">
                  <b-form-checkbox
                    v-model="profileData.ativo"
                    :checked="profileData.ativo"
                    name="check-button"
                    switch
                    inline
                    :disabled="
                      !verificarPermissao('ROLE_USER_ATUALIZAR') ||
                      !verificarPermissao('ROLE_USER_INSERIR')
                    "
                  >
                    {{ profileData.ativo ? 'Sim' : 'Não' }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <ButtonsFormVue
                :permission-insert="verificarPermissao('ROLE_USER_INSERIR')"
                :permission-edit="verificarPermissao('ROLE_USER_ATUALIZAR')"
                :disable-form="false"
                :loadingButton="loading"
                :showCancel="false"
                @save="save()"
                @cancel="cancel()"
              />
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- <b-row> -->
      <!-- about suggested page and twitter feed -->

      <!-- <b-col lg="3" cols="12" order="2" order-lg="1">
          <profile-about :about-data="profileData.userAbout" />
          <profile-suggested-pages :pages-data="profileData.suggestedPages" />
          <profile-twitter-feed :twitter-feed="profileData.twitterFeeds" />
        </b-col> -->
      <!--/ about suggested page and twitter feed -->

      <!-- post -->
      <!-- <b-col lg="6" cols="12" order="1" order-lg="2">
          <profile-post :posts="profileData.post" />
        </b-col> -->
      <!-- post -->

      <!-- latest photos suggestion and polls -->
      <!-- <b-col lg="3" cols="12" order="3">
          <profile-latest-photos :latest-images="profileData.latestPhotos" />
          <profile-suggestion :suggestions="profileData.suggestions" />
          <profile-polls :polls-data="profileData.polls" />
        </b-col> -->
      <!--/ latest photos suggestion and polls -->

      <!-- load more  -->
      <!-- <b-col cols="12" order="4">
          <profile-bottom />
        </b-col> -->
      <!--/ load more  -->
      <!-- </b-row> -->
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox
} from 'bootstrap-vue'

import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import mixinsGlobal from '@/mixins'

import ProfileHeader from './ProfileHeader.vue'
import ProfileAbout from './ProfileAbout.vue'
import ProfileSuggestedPages from './ProfileSuggestedPages.vue'
import ProfileTwitterFeed from './ProfileTwitterFeed.vue'
import ProfilePost from './ProfilePost.vue'
import ProfileLatestPhotos from './ProfileLatestPhotos.vue'
import ProfileSuggestion from './ProfileSuggestion.vue'
import ProfilePolls from './ProfilePolls.vue'
import profileBottom from './profileBottom.vue'

/* eslint-disable global-require */
export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('usersSettingAccessModuleModule', {
      user: (state) => state.user
    }),
    ...mapState('combosModule', {
      comboPerfils: (state) => state.comboPerfils
    })
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    ButtonsFormVue,
    ProfileHeader,
    ProfileAbout,
    ProfileSuggestedPages,
    ProfileTwitterFeed,
    ProfilePost,
    ProfileLatestPhotos,
    ProfileSuggestion,
    ProfilePolls,
    profileBottom
  },
  directives: {
    Ripple
  },
  data() {
    return {
      loading: false,
      required,
      profileData: {},
      userPlace: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        perfil: [
          {
            id: ''
          }
        ]
      }
    }
  },
  created() {
    const user = JSON.parse(localStorage.getItem('userData'))
    this.$store.dispatch('usersSettingAccessModuleModule/getById', user.id)
    this.$store.dispatch('combosModule/loadComboPerfils')
    this.profileData = user

    this.profileData.header = {
      avatar: require('@/assets/images/avatars/user.png'),
      coverImg: require('@/assets/images/banner/banner-12.jpg'),
      designation: user.email,
      username: user.username
    }

    // this.$http.get('/profile/data').then((res) => {})
  },
  mounted() {
    this.userPlace = JSON.parse(JSON.stringify(this.user))
  },
  methods: {
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.loading = true
          this.$store
            .dispatch('usersSettingAccessModuleModule/editUser', this.userPlace)
            .then((response) => {
              if (response.response) {
                this.MensagemError(
                  'Falha ao Editar',
                  response.response.data.error
                )
                return
              }
              this.showMessageSuccess('Editar', 'Editado com sucesso!')
              this.$refs.form.reset()
              this.loading = false
            })
            .catch((e) => {
              this.loading = false
            })
        }
      })
    }
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
</style>
